<template>
  <v-container class="mt-4">
    <v-row>
      <v-col>
        <v-row class="pl-1 pb-2">
          <h2>Hue</h2>
        </v-row>
        <v-slider
            v-model="hue"
            max="255"
            min="0"
            thumb-label
            @change="sliderChange"
        ></v-slider>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-row class="pl-1 pb-2">
          <h2>Brightness</h2>
        </v-row>
        <v-slider
            v-model="brightness"
            max="255"
            min="0"
            thumb-label
            @change="sliderChange"
        ></v-slider>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import mqtt from 'mqtt'

const mqttProto = "wss"
const mqttUrl = "websocket.iot.fr-par.scw.cloud"
//const mqttPort = "443"
const mqttDevice = "b5add294-1f5c-4862-9eca-a0b3285f1c20"

export default {
  name: "Main",

  data() {
    return {
      hue: 0,
      brightness: 0,
      mqttClient: {}
    }
  },

  mounted() {
    console.log('mounted')
    try {
      const mqttOptions = {
        clientId: mqttDevice,
        //protocolId: "MQTT"

      }
      this.mqttClient = mqtt.connect(`${mqttProto}://${mqttUrl}`, mqttOptions)

      // error
      this.mqttClient.on('error', function (err) {
        console.log(err)
        this.mqttClient.end()
      })

      // connected
      this.mqttClient.on('connect', function () {
        console.log('client connected !')
        //client.publish('topic', 'wss secure connection demo...!', { qos: 0, retain: false })
      })


    } catch (e) {
      console.log("mqtt connect error: " + e)
    }
  },

  methods: {
    sliderChange: function () {
      console.log(this.hue, this.brightness)
      let message = {
        Hue: this.hue,
        Brightness: this.brightness
      }
      this.mqttClient.publish('/mqtt/led', JSON.stringify(message), {qos: 0, retain: false})
    }

  }


}
</script>

<style scoped>

</style>